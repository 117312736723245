import type { AppProps } from 'next/app';

import Head from 'next/head';
import { ThemeProvider, CssBaseline } from '@mui/material';

import { theme } from 'styles/theme/theme';
import 'styles/globals.css';

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
