import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#f70404',
    },
    secondary: {
      main: '#00899c',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
  },
  typography: {
    fontFamily: '"Jost", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.2rem',
      fontWeight: 300,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 300,
    },
  },
};

export const theme = createTheme(themeOptions);
